<template>
  <el-container style="height:100%">
    <el-header>
      <div class="logo">馨佰拍管理后台</div>
      <el-menu :default-active="activeMenuHeader" mode="horizontal" @select="handleSelectMenuHeader" style="display:none">
        <el-menu-item v-for="item in menuHeader" :key="item.id" :index="item.path">{{ item.name }}</el-menu-item>
      </el-menu>
      <div class="user">欢迎：{{ userName }} <span style="padding:0 10px;">|</span> <el-button type="text" style="color:#FFFFFF" @click="logout">退出</el-button></div>
    </el-header>

    <el-container style="height:calc(100% - 60px)">
      <el-aside width="auto" v-show="menuAside.length > 0">
        <el-menu :default-active="activeMenuAside" class="el-menu-vertical" :collapse="isCollapse" router>
          <span v-for="item in menuAside" :key="item.id">
            <span v-if="getRootMenus(item.id).length === 0">
              <el-menu-item :index="item.path">
                <i :class="item.icon"></i><span v-show="!isCollapse">{{ item.name }}</span>
              </el-menu-item>
            </span>
            <span v-else>
              <el-submenu :index="item.path">
                <template slot="title">
                  <i :class="item.icon"></i>
                  <span v-show="!isCollapse">{{ item.name }}</span>
                </template>
                <el-menu-item v-for="itemChild in getRootMenus(item.id)" :key="itemChild.id" :index="itemChild.path">
                  <i :class="item.icon"></i>
                  <span slot="title">{{ itemChild.name }}</span>
                </el-menu-item>
              </el-submenu>
            </span>
          </span>
        </el-menu>
      </el-aside>

      <el-main>
        <div class="top" v-if="route.meta.length > 1">
          <div class="toggle">
            <el-button type="text" v-show="isCollapse" icon="el-icon-s-fold" @click="isCollapse = !isCollapse"> </el-button>
            <el-button type="text" v-show="!isCollapse" icon="el-icon-s-unfold" @click="isCollapse = !isCollapse"> </el-button>
          </div>
          <span>当前位置：</span>
          <el-breadcrumb separator="/">
            <transition-group name="breadcrumb" mode="out-in">
              <el-breadcrumb-item v-for="item in route.meta" :key="item.name">{{ item.name }}</el-breadcrumb-item>
            </transition-group>
          </el-breadcrumb>
          <span class="timer"><NowTime></NowTime></span>
        </div>
        <div class="main" :class="{ height100: menuAside.length === 0 }">
          <transition mode="out-in">
            <router-view />
          </transition>
        </div>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
const MENULIST = [
  {
    id: 1,
    parentId: 0,
    name: '0',
    path: '0',
    icon: null,
    level: 0,
    sort: 0,
  },
  {
    id: 2,
    parentId: 1,
    name: '业务',
    path: '/console',
    icon: 'el-icon-star-off',
    level: 1,
    sort: 2,
  },
  {
    id: 10,
    parentId: 2,
    name: '车辆管理',
    path: '/console/car',
    icon: 'el-icon-star-off',
    level: 2,
    sort: 1,
  },
  {
    id: 101,
    parentId: 10,
    name: '金融公司管理',
    path: '/console/car/sale',
    icon: 'el-icon-star-off',
    level: 3,
    sort: 1,
  },
  {
    id: 102,
    parentId: 10,
    name: '车辆管理',
    path: '/console/car/car',
    icon: 'el-icon-star-off',
    level: 3,
    sort: 2,
  },
  {
    id: 20,
    parentId: 2,
    name: '用户投标',
    path: '/console/bid',
    icon: 'el-icon-star-off',
    level: 2,
    sort: 2,
  },
  {
    id: 30,
    parentId: 2,
    name: '用户管理',
    path: '/console/user',
    icon: 'el-icon-star-off',
    level: 2,
    sort: 3,
  },
  {
    id: 40,
    parentId: 2,
    name: '财务管理',
    path: '/console/finance',
    icon: 'el-icon-star-off',
    level: 2,
    sort: 4,
  },
  {
    id: 401,
    parentId: 40,
    name: '收支明细',
    path: '/console/finance/record',
    icon: 'el-icon-star-off',
    level: 3,
    sort: 1,
  },
  {
    id: 402,
    parentId: 40,
    name: '退保证金',
    path: '/console/finance/refund',
    icon: 'el-icon-star-off',
    level: 3,
    sort: 2,
  },
  {
    id: 50,
    parentId: 2,
    name: 'banner图管理',
    path: '/console/banner',
    icon: 'el-icon-star-off',
    level: 2,
    sort: 4,
  },
  {
    id: 60,
    parentId: 2,
    name: '系统设置',
    path: '/console/sys',
    icon: 'el-icon-star-off',
    level: 2,
    sort: 4,
  },
  {
    id: 601,
    parentId: 60,
    name: '管理员列表',
    path: '/console/sys/admin',
    icon: 'el-icon-star-off',
    level: 3,
    sort: 1,
  },
  {
    id: 602,
    parentId: 60,
    name: '操作记录',
    path: '/console/sys/log',
    icon: 'el-icon-star-off',
    level: 3,
    sort: 2,
  },
];

import NowTime from './components/NowTime';

export default {
  components: {
    NowTime,
  },

  data() {
    return {
      menuHeader: [],
      menuAside: [],
      activeMenuHeader: '',
      activeMenuAside: '',

      isCollapse: false,

      route: this.$route,

      mainHeight: '100%',

      userName: '',
    };
  },

  mounted() {
    this.userName = localStorage.getItem('userName');

    this.menuHeader = MENULIST.filter((item) => item.level === 1);
    let arr = this.$route.path.split('/');
    //this.activeMenuHeader = '/' + arr[1] + '/' + arr[2];
    this.activeMenuHeader = '/' + arr[1];
    this.menuAside = MENULIST.filter((item) => item.path.indexOf(this.activeMenuHeader) > -1 && item.level === 2);
    this.activeMenuAside = this.$route.path;
  },

  watch: {
    $route(to, from) {
      this.route = this.$route;
      console.log(this.route);
    },
  },

  methods: {
    handleSelectMenuHeader(key, keyPath) {
      this.menuAside = MENULIST.filter((item) => item.path.indexOf(key) > -1 && item.level === 2);

      //这段是根据当前菜单结构写的代码，实际根据具体项目修改----------------------------
      if (this.getRootMenus(this.menuAside[0].id).length === 0) {
        this.$router.push(this.menuAside[0].path);
        this.activeMenuAside = this.menuAside[0].path;
      } else {
        this.$router.push(this.getRootMenus(this.menuAside[0].id)[0].path);
        this.activeMenuAside = this.getRootMenus(this.menuAside[0].id)[0].path;
      }
      //------------------------------------------------------------------------
    },

    //判断当前id菜单是否为根菜单
    isRootMenu(id) {
      return !MENULIST.some((item) => item.parentId == id);
    },

    //找到当前id菜单下的所有根菜单
    getRootMenus(id) {
      return MENULIST.filter((item) => item.parentId == id);
    },

    //
    logout() {
      localStorage.setItem('userName', '');
      localStorage.setItem('token', '');
      this.$message.success('退出成功');
      this.$router.push('/login');
    },
  },
};
</script>

<style lang="scss" scoped>
.el-header {
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px;
}
.logo {
  font-size: 28px;
  font-weight: bold;
  color: #ffffff;
}
.user {
  color: #ffffff;
}
</style>
