<template>
  <div class="content">
    {{ nowTime }}
  </div>
</template>

<script>
export default {
  name: 'Nowtime',

  components: {},

  data() {
    return {
      nowTime: '',
      timer: '',
    };
  },

  mounted() {
    this.nowTime = this.NowTime();
    this.timer = setInterval(() => {
      this.nowTime = this.NowTime();
    }, 1000);
  },

  destroyed() {
    clearTimeout(this.timer);
  },

  methods: {
    NowTime() {
      //获取年月日
      let time = new Date();
      let year = time.getFullYear();
      let month = time.getMonth() + 1;
      let day = time.getDate();

      //获取时分秒
      let h = time.getHours();
      let m = time.getMinutes();
      let s = time.getSeconds();

      //检查是否小于10
      h = this.check(h);
      m = this.check(m);
      s = this.check(s);


      let weeks = new Array('星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六');
      let week = weeks[time.getDay()];

      let nowTime = year + '年' + month + '月' + day + '日  ' + week + ' ' + h + ':' + m + ':' + s;
      return nowTime;
    },
    //时间数字小于10，则在之前加个“0”补位。
    check(i) {
      let num;
      i < 10 ? (num = '0' + i) : (num = i);
      return num;
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  display: inline-block;
}
</style>
